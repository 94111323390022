import React from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import FaqsNavBar from '../../components/FaqsNavBar'

const ShippingPage = () => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  const toggleAccordion = React.useCallback(
    (e, titleProps) => {
      const { index } = titleProps
      const newIndex = activeIndex === index ? -1 : index
      setActiveIndex(newIndex)
    },
    [activeIndex]
  )

  return (
    <>
      <Layout>
        <SEO
          title="Shipping FAQ"
          description="Do you have a question regarding shipping? You might find your answer from most frequently questions asked by other customers. If not, drop us a line. We will answer your question as soon as possible."
          meta={[
            {
              name: 'keywords',
              content:
                'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
            }
          ]}
        />
        <FaqsNavBar />
        <div className="container py-4 responsive-fh animated fadeInUp slow">
          <h2 className="text-center">Shipping Questions</h2>
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong> I live outside of the USA; Can you ship to me?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                We have shipped internationally to Saudi Arabia, Australia, Malta, and Canada.
                Please <Link to="/contact-us">contact us</Link> for details on shipping to your
                area.
              </p>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 1} index={1} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>
                  I live outside of the USA; Will this pedi-spa massage chair be compatible with my
                  electrical system?
                </strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                We are able to adjust the pedi-spa chair electrical system to meet your individual
                needs; please <Link to="/contact-us">contact us</Link> for more information.
              </p>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 2} index={2} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />

                <strong>How do I unpack the shipment and set up the pedi-spa massage chair?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <p>
                Please refer to the Unpacking and Hookup Procedures booklet for instructions on
                unpacking shipments and pedi-spa massage chair hookup.
              </p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>What if my order shipment arrives with damages on the box?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <p>
                Refuse the shipment, or state on signed paper, if there are any visible damages to
                the shipment. Transportation damages must be claimed within Forty-Eight (48) hours
                after receiving the products by{' '}
                <Link to="/contact-us">contacting customer support</Link> at Contégo.
              </p>
            </Accordion.Content>
          </Accordion>
        </div>
      </Layout>
    </>
  )
}

export default ShippingPage
